.state-consultation-inspiration {
  div h2 {
    padding: 25px 0px
  }
  @include square-image-with-border;
}

.state-confirmation,
.state-confirmation .row,
.state-consultation-contactInfo,
.state-consultation-contactInfo .row {
  max-width: none;
}
