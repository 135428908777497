.state-welcome-are-you-ready {
  @media screen and (min-width: 40em) {
    .tile-container {
      // padding: 0px 0px 0px 15px;
      background: #EBEBEB;
    }
    .tile-inner-border {
      border: #F00860 20px solid;
      border-right: none;
      // padding: 0px 15px;
    }
  }
}

.state-welcome-are-you-ready,
.state-welcome-expand-url,
[class*="state-consultation"],
.state-confirmation {

  // hide the scrollbar (works in Chrome)
  ::-webkit-scrollbar {
    width: 0;
  }

  .holder.row {
    overflow-y: scroll;
  }

  .tile-container {
    @media screen and (max-width: 40em) {
      position: absolute;
      bottom: -20px;
      padding: 0;
      left: 0;
      height: 118px;
      padding: 0px 5px;
      overflow-x: scroll;
      overflow-y: hidden;
      overflow-y: scroll;
      white-space: nowrap;
      .tile.single {
        // width: 12.5%;
        height: 107px;
        display: inline;
        float: left;
        padding: 0;
        border: 5px solid white;
        box-sizing: border-box;
      }
    }
    @media screen and (min-width: 40em) {
      bottom: 0px;
    }
  }

  [ui-view] {
    max-height: 100vh;
  }
  [ui-view="tiles"] {
    overflow-y: scroll;
  }

  #question-container {
    .main-header {
      font-size: 35px;
      font-family: 'Elena Italic';
      font-weight: bold;
      display: block;
      line-height: 45px;
      text-align: center;
      @media screen and (max-width: 40em) {
        font-size: 26px;
        line-height: 29px;
      }
    }

    #onboarding {
      p {
        font-size: 20px;
        line-height: 40px;
        @media screen and (max-width: 40em) {
          font-size: 14px;
          line-height: rem-calc(26);
        }
      }
    }

    #snapsnip-logo-container {
    }

    @include sub-title;

    @include paragraph-text;

    @include button-mixin;

    div#header-container {
      // top: 145px;
      // position: relative;
      text-align: center;

      h2 {
        font-size: 35px;
      }
    }

    img {
      display: block;
      margin: 0px auto;
    }

  }

  .tile-overlay {
    opacity: 0;
    display: none;
    &:hover {
      opacity: 0.8;
      transition: opacity 0.5s;
    }
  }


  .tile.single {
  //   border: 10px solid #fff;
  //   box-sizing: content-box;
  //   width: 200px;
  //   margin: 10px;
  //
  //   img {
  //     position: relative;
  //     top: -247px;
  //
  //     &.profile-image {
  //       top: 0;
  //     }
  //   }
  //
  //
  &:nth-of-type(4n) {
    //     margin-left: 0;
    //     margin-right: 10px;
    // border: 2px solid orange;
  }
    &:nth-of-type(4n+1) {
  //     margin-right: 0;
  //     margin-left: 10px;
      // border: 2px solid red;
    }
  //   &:nth-of-type(2),
  //   &:nth-of-type(3),
  //   &:nth-of-type(4),
  //   &:nth-of-type(5) {
  //     margin-top: 0;
  //   }
  //
  }

  @include spinner;
}
