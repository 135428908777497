.state-canvas-question,
.state-canvas-question .row {

  ul {
    margin-left: 0px;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    li {
      list-style: none;
    }
  }
  @media only screen and (max-width: 374px) {
    h1 {
      padding: 25px;
    }
    ul {
      margin-top: 0px;
      display: inline-block;
      li {
        margin-top: 0px;
        margin-bottom: 0px
      }
    }
  }
}

.state-canvas-question {
  max-width: none;
}