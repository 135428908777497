[class*="state-consultation"],
[class*="state-canvas"],
.state-selectSalon,
.state-confirmation {
  .holder {
    background: #fff;
  }

  .inspiration-header {
    padding: 10px 0px;
  }

  .full-width {
    width: 100%;
  }

  .flex {
    display: flex;
  }

  .flex-children-middle {
    align-items: center;
  }

  .flex-center {
    justify-content: center;
  }

  .tile-container {
    position: fixed;
    left: 0px;
    bottom: 15px;
    @media screen and (max-width: 40em) {
      bottom: -10px;
    }
  }
  @media screen and (max-width: 40em) {
    .tile-container {
      height: 115px;
    }
  }

  .mobile-bottom-logo {
    @media only screen and (min-width: 40em) {
      visibility: hidden;
    }
  }

  @media screen and (min-width: 40em) {
    .holder {
      padding: 15px 40px 0px 40px;
    }
  }

  @include font-sizes;
  @include text-area;
  @include selfie-image;
  @include sub-title;
  @include paragraph-text;
  @include title-font;
  @include paragraph-text;

  @include button-mixin;

  @include footer-tiles;
  @include select-mixin;

  @include fixed-logo-position;
  @include spinner;
}
