.state-errorState {
  color: white;


  @include font-sizes;
  @include button-mixin;
  @include sub-title;

  .water-mark {
    padding: 35px 0px;
  }

  h2 {
    @media only screen and (min-width: 40em){
      font-size: 2.2em;
    }
  }

  .error-button button{
    @media screen and (max-width: 40em){
      margin: 35px 0px !important;
    }
  }

  #error-container {
    height: 100vh;
  }
}
