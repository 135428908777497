@font-face {
  font-family: 'Elena';
  src: url('http://snap-snip-production.s3.amazonaws.com/fonts/elena/Elena-Regular.otf');
}
@font-face {
  font-family: 'Elena Italic';
  src: url('http://snap-snip-production.s3.amazonaws.com/fonts/elena/Elena-RegularItalic.otf');
}
@font-face {
  font-family: 'Elena Bold';
  src: url('http://snap-snip-production.s3.amazonaws.com/fonts/elena/Elena-Bold.otf');
}
@font-face {
  font-family: 'Elena Bold Italic';
  src: url('http://snap-snip-production.s3.amazonaws.com/fonts/elena/Elena-BoldItalic.otf');
}
//---------Roboto
@font-face {
  font-family: 'Roboto Black';
  src: url('http://snap-snip-production.s3.amazonaws.com/fonts/roboto/Roboto-Black.ttf');
}
@font-face {
  font-family: 'Roboto Bold';
  src: url('http://snap-snip-production.s3.amazonaws.com/fonts/roboto/Roboto-Bold.ttf');
}
@font-face {
  font-family: 'Roboto Medium';
  src: url('http://snap-snip-production.s3.amazonaws.com/fonts/roboto/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto Medium';
  src: url('http://snap-snip-production.s3.amazonaws.com/fonts/roboto/Roboto-Regular.ttf');
}
