.state-confirmation {
  .flex {
    display: flex;
  }
  .flex-children-middle {
    align-items: center;
  }
  .flex-children-center {
    justify-content: center;
  }
  .question-view {
    @media only screen and (max-width: 40em){
      margin-top: 50px;
    }
  }
  ul{
    margin: 30px 0px;
    list-style: none;
    display: inline-flex;
    li {
      height: 60px;
      width: 60px;
      border-radius: 35px;
      margin: 0px 20px;
      i {
        font-size: 30px;
        color: #fff;
      }
    }
  }
  h1 {
    margin: 0px;
    padding: 0px;
    @media only screen and (max-width: 40em){
      font-size: 3.8em;
    }
  }
  #question-container h2 {
    font-size: 1.7em;
    padding: 0px 0px 15px 0px;
    font-family: 'Elena Italic';
    font-weight: 100;
    @media only screen and (max-width: 40em){
      font-size: 1.9em;
    }
  }
  img {
    max-height: 300px;
  }

  .bg-facebook {
    background: rgb(70, 101, 172);
  }
  .bg-twitter {
    background: rgb(20, 196, 248);
  }
  .bg-instagram {
    background: rgb(211, 197, 178);
  }

}
